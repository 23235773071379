import React from 'react';

export default class introduction extends React.Component {
    render() {
        return (
            <div>
                Welcome to my Portfolio site! This site is currently under construction as I am actively learning React & AWS. This site is a React application being powered by AWS Amplify w/ automatic deploys.
            </div>
        );
    }
}
